import React, { useContext } from "react";
import ArticleHeading from "../Heading/article-heading";
import classnames from "classnames";
import StoryCard from "./story-card";
import { BreakpointContext } from "@/context/BreakpointContext";
import ScrollCarousel from "../Carousel/scroll-carousel";
import { compareAsc, compareDesc, parseISO } from "date-fns";
import { bpInRange } from "@/context/BreakpointContext";
import Link from "@/components/Link";
import styles from "./story-cards.module.scss";
import { RichtextStoryblok } from "@/types/component-types-sb";

interface Props {
  title?: RichtextStoryblok | string;
  titleSlug?: string;
  stories: any[];
  layout: string[];
  aspect: string[];
  storiesPerRow: string[];
  children?: React.ReactNode;
  className: string;
  sort: string;
  noLink?: boolean;
  CardComponent?: any;
}

const StoryCards = (props: Props) => {
  const { currentBp } = useContext(BreakpointContext);
  const {
    title,
    titleSlug,
    stories,
    layout = ["carousel", "3"],
    aspect = ["3/2", "1/1"],
    storiesPerRow,
    children,
    className,
    sort = "desc",
    noLink = false,
    CardComponent = StoryCard,
  } = props;

  const pickBySize = (arr: string[]) => {
    if (!currentBp || bpInRange({ currentBp, bp: "m", range: "down" })) {
      return parseInt(arr[0], 10) || "carousel";
    } else {
      return parseInt(arr[1], 10) || "carousel";
    }
  };
  const currentLayout = stories && stories.length > 1 ? pickBySize(layout) : 1;
  const currentPerRow =
    stories && stories.length > 1 && storiesPerRow
      ? pickBySize(storiesPerRow)
      : null;
  const currentToShow = currentLayout === "carousel" ? 4 : currentLayout;

  const layoutClass = (curr: string | number) => {
    return curr === "carousel" && stories.length > 1
      ? `${styles.showCarousel} layout-grid`
      : currentPerRow
      ? styles[`show-${currentPerRow}`]
      : styles[`show-${currentToShow}`];
  };

  const gridClasses = classnames(
    styles.storyCardsGrid,
    layoutClass(currentLayout)
  );
  const classes = classnames(
    "story-cards",
    layoutClass(currentLayout),
    className
  );

  return (
    <div className={classes}>
      {titleSlug ? (
        <Link className="story-cards-title__link" href={titleSlug}>
          <ArticleHeading
            blok={{
              content:
                typeof title === "string"
                  ? {
                      type: "paragraph",
                      text: title,
                    }
                  : title,
              _uid: "",
              component: "article-heading",
            }}
            className={styles.articleHeading}
          />
        </Link>
      ) : (
        <ArticleHeading
          blok={{
            content:
              typeof title === "string"
                ? {
                    type: "paragraph",
                    text: title,
                  }
                : title,
            _uid: "",
            component: "article-heading",
          }}
          className={styles.articleHeading}
        />
      )}
      {currentLayout === "carousel" ? (
        <ScrollCarousel hideControls>
          {Stories({
            stories,
            currentToShow,
            aspect,
            sort,
            CardComponent,
            noLink,
          })}
        </ScrollCarousel>
      ) : (
        <div className={gridClasses}>
          {Stories({
            stories,
            currentToShow,
            aspect,
            sort,
            CardComponent,
            noLink,
          })}
        </div>
      )}
      {children && <div className="story-cards-children">{children}</div>}
    </div>
  );
};

interface StoriesProps {
  stories: any[];
  currentToShow: number;
  aspect: string[];
  sort: string;
  CardComponent: any;
  noLink: boolean;
}

const Stories = ({
  stories,
  currentToShow,
  aspect,
  sort = "desc",
  CardComponent,
  noLink,
}: StoriesProps) =>
  stories &&
  stories.length > 0 &&
  stories
    .map(
      (story, i) =>
        i < currentToShow && (
          <CardComponent
            key={story?.content?._uid}
            image={
              story?.content?.article_image ||
              story?.content?.poster ||
              story?.content?.profile_image
            }
            title={story?.name}
            slug={!noLink ? `/${story?.full_slug}` : null}
            aspectMobile={aspect[0]}
            aspectDesktop={aspect[1]}
            story={story}
          />
        )
    )
    .sort((a: any, b: any) => {
      return sort === "desc"
        ? compareDesc(
            parseISO(a.first_published_at),
            parseISO(b.first_published_at)
          )
        : compareAsc(
            parseISO(a.first_published_at),
            parseISO(b.first_published_at)
          );
    });

export default StoryCards;
