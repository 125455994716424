// @ts-expect-error
import { injectScopedInternalComponentList } from "@ef-global/sbc-utils";
import dynamic from "next/dynamic";
import InlineVideo from "@/components/Video/Inline/inline-video";
import WebUITable from "@/components/@ef-global/web-ui-table/web-ui-table";
import ConsultationForm from "@/components/Form/consultation-form/consultation-form";
import EliteForm from "@/components/Form/elite-form/elite-form";
import GironaCamp from "@/components/Form/girona-camp/girona-camp";
import VideoCards from "@/components/Video/Card/video-cards";
import VideoCard from "@/components/Video/Card/video-card";

const Placeholder = dynamic(import("./Placeholder"));
const HomePage = dynamic(import("./Homepage/home"));
const Page = dynamic(import("./Page/page"));
const ProtectedPage = dynamic(import("./ProtectedPage/protected-page"));
const ContentHubPage = dynamic(import("./ProtectedPage/content-hub-page"));
const Blog = dynamic(import("./Blog/blog"));
const BlogIndex = dynamic(import("./Blog/blog-index"));
const CoachesIndex = dynamic(import("./Coaches/coaches-index"));
const CoachSection = dynamic(import("./Coaches/coach-section"));
const Coach = dynamic(import("./Coaches/coach"));
const Button = dynamic(import("./Button/sb-button"));
const FoundationsButton = dynamic(import("./Button/foundations-button"));
const ButtonGroup = dynamic(import("./Button/button-group"));
const EventsIndex = dynamic(import("./Events/events-index"));
const Event = dynamic(import("./Events/event"));
const EventPricingCards = dynamic(import("./Events/event-pricing-cards"));
const Accordion = dynamic(import("./Accordion/accordion"));

const Carousel = dynamic(import("./Carousel/carousel"));
const CoverflowCarousel = dynamic(import("./Carousel/coverflow-carousel"));
const TextBlock = dynamic(import("./TextBlock/sb-text-block"));
const ArticleList = dynamic(import("./List/list"));
const Divider = dynamic(import("./Divider/divider"));

const Image = dynamic(import("./Image/sb-image"));
const LayoutGrid = dynamic(import("./Layout/sb-layout-grid"));
const LayoutGroup = dynamic(import("./Layout/sb-layout-group"));
const LayoutRow = dynamic(import("./Layout/sb-layout-row"));

const CustomHeading = dynamic(import("./Heading/custom-heading"));
const Section = dynamic(import("./Section/sb-section"));
const HeroVideo = dynamic(import("./Video/Hero/hero-video"));
const VideoPlayer = dynamic(import("./Video/Player/video-player"));
const ScrollingCards = dynamic(import("./ScrollingCards/scrolling-cards"));

const Card = dynamic(import("./Card/card-sb"));
const Calendly = dynamic(import("./Calendly/calendly"));
const TrainingPlanCard = dynamic(
  import("./TrainingPlanCard/training-plan-card")
);

const PricingCardsCondensed = dynamic(
  import("@/components/PricingCards/pricing-cards-condensed")
);
const PricingCardsExtended = dynamic(
  import("@/components/PricingCards/pricing-cards-extended")
);
const ArticleHeading = dynamic(import("@/components/Heading/article-heading"));
const TrainingPlansBox = dynamic(
  import("@/components/TrainingPlans/training-plans-box")
);

// Content
const Blockquote = dynamic(import("./Blockquote/blockquote"));

const CardList = dynamic(import("./CardList/CardList"));
const CardListItem = dynamic(import("./CardList/CardListItem"));
const Swiper = dynamic(import("./Swiper/Swiper"));
const CardHeaderAndText = dynamic(
  import("./CardHeaderAndText/CardHeaderAndText")
);
const GradientCard = dynamic(import("./GradientCard/GradientCard"));
const CardImage = dynamic(import("./CardImage/CardImage"));
const CampPaymentCard = dynamic(import("./CampPaymentCard/CampPaymentCard"));

const IconWithTextList = dynamic(import("./IconWithText/IconWithTextList"));
const IconWithText = dynamic(import("./IconWithText/IconWithText"));

const CardLInks = dynamic(import("./CardLinks/CardLinks"));
const CardLink = dynamic(import("./CardLinks/CardLink"));

const EventStickyFooter = dynamic(import("./Events/event-sticky-footer"));

const withComponentList =
  (type: string) => (componentList: Record<string, any>) => {
    if (typeof componentList[type] === "undefined") {
      return componentList["placeholder"];
    } else {
      return componentList[type];
    }
  };

const Components = (type: string) => withComponentList(type)(ComponentList);

const LocalComponentList = {
  placeholder: Placeholder,
  page: Page,
  "protected-page": ProtectedPage,
  "content-hub-page": ContentHubPage,
  home: HomePage,
  blog: Blog,
  "blog-index": BlogIndex,
  button: Button,
  "button-group": ButtonGroup,
  "coaches-page": CoachesIndex,
  coach: Coach,
  "coach-section": CoachSection,
  "custom-heading": CustomHeading,
  "events-index": EventsIndex,
  event: Event,
  "event-pricing-cards": EventPricingCards,
  "text-block": TextBlock,
  "article-text-block": TextBlock,
  "article-heading": ArticleHeading,
  list: ArticleList,
  image: Image,
  "article-image": Image,
  section: Section,
  "layout-grid": LayoutGrid,
  "layout-group": LayoutGroup,
  "layout-row": LayoutRow,
  divider: Divider,
  "image-carousel": Carousel,
  "content-carousel": Carousel,
  "coverflow-carousel": CoverflowCarousel,
  "pricing-cards-condensed": PricingCardsCondensed,
  "pricing-cards-extended": PricingCardsExtended,
  "hero-video": HeroVideo,
  "inline-video": InlineVideo,
  "scrolling-cards": ScrollingCards,
  blockquote: Blockquote,
  accordion: Accordion,
  "consultation-form": ConsultationForm,
  "elite-form": EliteForm,
  "girona-camp": GironaCamp,
  "web-ui-table": WebUITable,
  "training-plans-box": TrainingPlansBox,
  "video-cards": VideoCards,
  "video-card": VideoCard,
  "video-player": VideoPlayer,
  "foundations-button": FoundationsButton,
  card: Card,
  calendly: Calendly,
  "training-plan-card": TrainingPlanCard,
  "card-list": CardList,
  "card-list-item": CardListItem,
  swiper: Swiper,
  "card-header-and-text": CardHeaderAndText,
  "gradient-card": GradientCard,
  "card-image": CardImage,
  "camp-payment-card": CampPaymentCard,
  "icon-with-text-list": IconWithTextList,
  "icon-with-text": IconWithText,
  "card-links": CardLInks,
  "card-link": CardLink,
  "event-sticky-footer": EventStickyFooter,
};

const ScopedComponentList = {
  // The injectScopedInternalComponentList is method to inject whole components.js file if scope
  // component have more internal components than just one
  ...injectScopedInternalComponentList(
    {
      components: [
        // --- sb-mig scoped component list ---
        // {...ScopedWebUISurface.ComponentList, ...{'web-ui-surface': withLink(ScopedWebUISurface.WebUISurface, Link)}},
      ],
    },
    Components // injecting all Components you will use normally, into any of scoped components
  ),
  // If you are using scoped component, but inside your local project (installed with sb-mig add component-name --copy)
  // then use this syntax to import and use component and to inject component list to it
  // surface: withComponents(Surface)(Components)
  // "web-ui-surface": withComponents(SurfaceStuff.WebUISurface)(Components)
};

const ComponentList = {
  ...ScopedComponentList,
  ...LocalComponentList,
};

export default Components;
