import React, { createElement, useContext } from "react";
import SbEditable from "storyblok-react";
import { bpInRange, BreakpointContext } from "@/context/BreakpointContext";
import { NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer";
import classnames from "classnames";
import styles from "./article-heading.module.scss";
import { ArticleHeadingStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: ArticleHeadingStoryblok;
  className?: string;
  renderDefault?: string;
  children?: React.ReactNode;
}

const ArticleHeading = (props: Props) => {
  const { currentBp } = useContext(BreakpointContext);
  const smallViewport = bpInRange({ currentBp, bp: "m", range: "down" });
  const {
    blok: { content, alignment = "", alignment_large_screens },
    className,
    renderDefault = "h3",
    children,
  } = props;

  const currAlignment = smallViewport
    ? styles[alignment]
    : styles[alignment_large_screens] || styles[alignment];
  const classes = classnames(styles.heading, currAlignment, className);

  const paraRenderer = (children: React.ReactNode) =>
    createElement(renderDefault, {}, children);

  const textChildren = render(content, {
    nodeResolvers: {
      [NODE_PARAGRAPH]: paraRenderer,
    },
  });

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <div className={classes}>
        {children}
        {textChildren}
      </div>
    </SbEditable>
  );
};

export default ArticleHeading;
