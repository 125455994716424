import React from "react";
import classnames from "classnames";
import Link from "@/components/Link";
import Image from "@/components/Image/image";
import PropTypes from "prop-types";
import { format } from "date-fns";
import styles from "./story-card.module.scss";
import { AssetStoryblok, ImageStoryblok } from "@/types/component-types-sb";
import { Story } from "@/types/storyblok";

interface Props {
  image: AssetStoryblok;
  title?: string;
  slug: string;
  url: string;
  sizes?: string;
  aspectMobile: ImageStoryblok["aspect_ratio_mobile"];
  aspectDesktop: ImageStoryblok["aspect_ratio_desktop"];
  className: string;
  children: React.ReactNode;
  story: Story;
}

const StoryCard = (props: Props) => {
  const {
    image,
    title,
    slug,
    url,
    sizes,
    aspectMobile,
    aspectDesktop,
    className,
    children,
    story,
  } = props;

  const classes = classnames("story-card", className);

  return slug || url ? (
    <Link className={classes} href={slug ? slug : url}>
      <CardContent
        image={image}
        title={title}
        aspectMobile={aspectMobile}
        aspectDesktop={aspectDesktop}
        sizes={sizes}
        date={story?.first_published_at}
      >
        {children}
      </CardContent>
    </Link>
  ) : (
    <div className={classes}>
      <CardContent
        image={image}
        title={title}
        aspectMobile={aspectMobile}
        aspectDesktop={aspectDesktop}
        sizes={sizes}
        date={story?.first_published_at}
      >
        {children}
      </CardContent>
    </div>
  );
};

interface CardContentProps {
  image: AssetStoryblok;
  title?: string;
  aspectMobile: ImageStoryblok["aspect_ratio_mobile"];
  aspectDesktop: ImageStoryblok["aspect_ratio_desktop"];
  sizes?: string;
  date?: string | null;
  children: React.ReactNode;
}

const CardContent = (props: CardContentProps) => {
  const { image, children, title, aspectMobile, aspectDesktop, sizes, date } =
    props;

  return (
    <>
      {image && (
        <Image
          alt={image.alt}
          aspectMobile={aspectMobile}
          aspectDesktop={aspectDesktop}
          filename={image.filename}
          focus={image.focus}
          sizes={sizes}
          className={styles.storyImage}
        />
      )}
      {children ||
        (title && (
          <div className={classnames("index-item__title", styles.title)}>
            {children ? (
              children
            ) : (
              <p className="u-mb-0 ef-text-ui">
                <span className={styles.storyCardDate}>
                  {date ? format(new Date(date), "MMMM d, yyyy") : null}
                </span>
                <strong>{title}</strong>
              </p>
            )}
          </div>
        ))}
    </>
  );
};

StoryCard.propTypes = {
  content: PropTypes.shape({
    article_image: PropTypes.shape({
      filename: PropTypes.string,
      aspectMobile: PropTypes.string,
      aspectDesktop: PropTypes.string,
      className: PropTypes.string,
      focus: PropTypes.string,
      alt: PropTypes.string,
      priority: PropTypes.bool,
      sizes: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  full_slug: PropTypes.string,
  featured: PropTypes.bool,
  className: PropTypes.string,
};

export default StoryCard;
