import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  Children,
} from "react";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import IconArrowLeft from "@ef-global/web-ui-react/lib/icons/IconArrowLeft";
import classnames from "classnames";
import styles from "./scroll-carousel.module.scss";

interface Props {
  children: React.ReactNode;
  hideControls?: boolean;
  type?: string;
  className?: string;
}

const ScrollCarousel = (props: Props) => {
  const { children, hideControls, type, className } = props;
  let slideRefs = useRef<HTMLDivElement[]>([]);
  const carouselRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const typeNames: Record<string, "image" | "content"> = {
    "image-carousel": "image",
    "content-carousel": "content",
  };
  const carouselType = type ? typeNames[type] || "image" : "image";

  const addToRefs = (el: HTMLDivElement) => {
    if (slideRefs.current && el !== null && !slideRefs.current.includes(el)) {
      slideRefs.current.push(el);
    }
  };

  const scrollToSlide = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    const carouselWidth =
      wrapperRef.current?.getBoundingClientRect().width || window.innerWidth;
    const lastSlideIndex = slideRefs.current.length - 1 || 0;
    if (index >= 0 && index <= lastSlideIndex) {
      const scrollAmount =
        index > currentSlide ? carouselWidth / 2 : -carouselWidth / 2;
      carouselRef.current?.scrollBy({ left: scrollAmount });
    }
  };

  useEffect(() => {
    if (typeof window === "undefined" || !("IntersectionObserver" in window)) {
      return;
    }

    const onChange = (changes: IntersectionObserverEntry[]) => {
      changes.forEach((change) => {
        change.isIntersecting &&
          setCurrentSlide(
            slideRefs.current.indexOf(change.target as HTMLDivElement)
          );
      });
    };
    let observer = new IntersectionObserver(onChange, {
      root: carouselRef.current,
      threshold: 1,
    });
    slideRefs.current.length > 0 &&
      slideRefs.current.forEach((slide) => observer.observe(slide));
    return () => observer && observer.disconnect();
  }, [slideRefs]);

  return (
    <div
      className={classnames(styles.container, styles[carouselType], className)}
      ref={wrapperRef}
    >
      <div className={styles.carousel} ref={carouselRef}>
        {Children.map(children, (child, i) => (
          <Slide
            key={i}
            ref={addToRefs}
            className={currentSlide === i ? styles.active : ""}
          >
            {child}
          </Slide>
        ))}
      </div>
      {!hideControls && carouselType === "image" ? (
        <ImageCarouselControls
          currentSlide={currentSlide}
          scrollToSlide={scrollToSlide}
          slideRefs={slideRefs}
        />
      ) : null}
      {!hideControls && carouselType === "content" ? (
        <ContentCarouselControls
          currentSlide={currentSlide}
          scrollToSlide={scrollToSlide}
          slideRefs={slideRefs}
        />
      ) : null}
    </div>
  );
};

const ImageCarouselControls = ({
  currentSlide,
  scrollToSlide,
  slideRefs,
}: {
  currentSlide: number;
  scrollToSlide: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
  slideRefs: React.MutableRefObject<HTMLDivElement[]>;
}) => {
  return (
    <div className={styles.imageControls}>
      <div className={styles.scrollCarouselButtons}>
        <button
          type="button"
          className={classnames(
            styles.scrollCarouselButton,
            currentSlide === 0 ? styles.inactive : ""
          )}
          onClick={(e) => scrollToSlide(e, currentSlide - 1)}
        >
          <IconArrowLeft />
        </button>
        <button
          type="button"
          className={classnames(
            styles.scrollCarouselButton,
            currentSlide === slideRefs.current.length - 1 ? styles.inactive : ""
          )}
          onClick={(e) => scrollToSlide(e, currentSlide + 1)}
        >
          <IconArrowRight />
        </button>
      </div>
    </div>
  );
};

const ContentCarouselControls = ({
  currentSlide,
  scrollToSlide,
  slideRefs,
}: {
  currentSlide: number;
  scrollToSlide: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
  slideRefs: React.MutableRefObject<HTMLDivElement[]>;
}) => {
  return (
    <div className={styles.contentControls}>
      <button
        type="button"
        className={classnames(
          styles.controlButtonRound,
          styles.controlButtonPrev,
          currentSlide === 0 ? styles.inactive : ""
        )}
        onClick={(e) => scrollToSlide(e, currentSlide - 1)}
      >
        <IconArrowLeft />
      </button>
      <button
        type="button"
        className={classnames(
          styles.controlButtonRound,
          styles.controlButtonNext,
          currentSlide === slideRefs.current.length - 1 ? styles.inactive : ""
        )}
        onClick={(e) => scrollToSlide(e, currentSlide + 1)}
      >
        <IconArrowRight />
      </button>
    </div>
  );
};

const Slide = forwardRef<
  HTMLDivElement,
  { className?: string; children: React.ReactNode }
>((props, ref) => {
  const { className, children } = props;
  const classes = classnames(styles.itemWrapper, className);
  return (
    <div ref={ref} className={classes}>
      <div className={styles.item}>{children}</div>
    </div>
  );
});

export default ScrollCarousel;
