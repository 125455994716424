import {
  CheckboxInput,
  SelectInput,
  TextInput,
} from "@/components/Form/inputs";
import styles from "./elite-form.module.scss";
import { useForm } from "react-hook-form";
import Button from "@/components/Button/button";
import CountryList from "@/components/Form/country-list";
import classnames from "classnames";
import * as gtag from "@/lib/gtag";
import { EliteFormStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: EliteFormStoryblok;
}

const EliteForm = (props: Props) => {
  const {
    blok: { submit_url = "", submit_success_message },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data: unknown) => {
    gtag.event({
      action: "User submitted for elite account",
      category: "Elite",
      label: `Elite-Form`,
      value: true,
    });

    await fetch(submit_url, {
      method: "POST",
      headers: {
        accept: "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  return !isSubmitSuccessful ? (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.eliteForm}>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset,
          styles.personalDetails
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>Personal details</legend>
          <TextInput
            valid={!errors.name && touchedFields.name}
            invalid={errors.name && touchedFields.name}
            placeholder={"Name"}
            tabIndex={1}
            {...register("name", { required: "Please enter your name" })}
          />
          <SelectInput
            valid={!errors.country && touchedFields.country}
            invalid={errors.country && touchedFields.country}
            required
            tabIndex={3}
            {...register("country", { required: "Please select your country" })}
            className={styles.consultationSelect}
          >
            <CountryList />
          </SelectInput>
        </div>
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            How should we contact you?
          </legend>
          <TextInput
            valid={!errors.email && touchedFields.email}
            invalid={errors.email && touchedFields.email}
            placeholder={"Email"}
            tabIndex={2}
            {...register("email", {
              required: "Please enter your email",
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
          />
          <TextInput
            valid={!errors.mobile && touchedFields.mobile}
            placeholder={"Mobile phone"}
            tabIndex={4}
            {...register("mobile", {
              required: "Please enter your mobile number",
            })}
          />
          <SelectInput
            valid={
              !errors.bestTimeToBeContacted &&
              touchedFields.bestTimeToBeContacted
            }
            invalid={
              errors.bestTimeToBeContacted &&
              touchedFields.bestTimeToBeContacted
            }
            required
            {...register("bestTimeToBeContacted", { required: true })}
          >
            <option value="">Best time to be contacted</option>
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
            <option value="evening">Evening</option>
            <option value="allDay">All day</option>
          </SelectInput>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <div className={styles.consultationCheckboxGroup}>
            <CheckboxInput
              valid={!errors.policy}
              invalid={errors.policy}
              inputClassName={styles.scheduleCheckboxInput}
              tabIndex={5}
              {...register("policy", { required: true })}
            >
              I agree to my personal data being processed in accordance with our{" "}
              <a href="/privacy-policy" title="privacy policy" target="_blank">
                Privacy Policy.
              </a>
            </CheckboxInput>
          </div>
        </div>
      </fieldset>
      <div className={styles.consultationSection}>
        <div className={styles.consultationDetails}>
          <Button
            type={`submit`}
            filled
            secondary
            tabIndex={6}
            className={styles.submitButton}
          >
            Send
          </Button>
        </div>
      </div>
    </form>
  ) : (
    <p className={`ef-text-subtitle-small u-mt-xxl`}>
      {submit_success_message}
    </p>
  );
};

export default EliteForm;
