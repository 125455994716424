import React, { useEffect, useState } from "react";
import NextImage from "next/image";
import classnames from "classnames";
import {
  cropDims,
  originalDimensions,
  cropDimsAroundFocusPoint,
  cropWithFocusPoint,
  resize,
} from "./image-helpers";
import styles from "./image.module.scss";
import { ImageStoryblok } from "@/types/component-types-sb";

interface Props {
  filename: ImageStoryblok["image"]["filename"];
  aspectMobile?: ImageStoryblok["aspect_ratio_mobile"];
  aspectDesktop?: ImageStoryblok["aspect_ratio_desktop"];
  focus?: ImageStoryblok["image"]["focus"];
  className?: string;
  alt?: string;
  priority?: boolean;
  sizes?: string;
}

const Image = (props: Props) => {
  const {
    filename = "",
    aspectMobile,
    aspectDesktop,
    focus,
    className,
    alt,
    priority,
    sizes,
  } = props;

  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean | undefined>(
    undefined
  );
  const originalSize = originalDimensions(filename);
  const intrinsicAspect = `${originalSize.width}/${originalSize.height}`;

  useEffect(() => {
    const mq = window.matchMedia("(max-width: 1024px)");
    setIsSmallScreen(mq.matches);

    const updateIsSmallScreen = (e: MediaQueryListEvent) => {
      setIsSmallScreen(e.matches);
    };

    mq.addEventListener("change", updateIsSmallScreen);

    return () => mq.removeEventListener("change", updateIsSmallScreen);
  }, [isSmallScreen, aspectMobile, aspectDesktop, focus, filename]);

  const classes = classnames(
    styles.image,
    "article-image",
    imageIsLoaded ? styles.loaded : "",
    className
  );

  const loader = ({ src, width }: { src: string; width: number }) => {
    let cropped;
    const originalSize = originalDimensions(src);

    const { cropWidth, cropHeight } = cropDims({
      width: originalSize.width,
      height: originalSize.height,
      aspectRatio: isSmallScreen
        ? aspectMobile || intrinsicAspect
        : aspectDesktop || aspectMobile || intrinsicAspect,
    });

    const scale = Number(width) / Number(cropWidth);

    if (focus) {
      const { xCropStart, xCropEnd, yCropStart, yCropEnd } =
        cropDimsAroundFocusPoint({
          originalWidth: originalSize.width,
          originalHeight: originalSize.height,
          cropWidth,
          cropHeight,
          focus,
        });
      cropped = `${cropWithFocusPoint({
        xCropStart,
        xCropEnd,
        yCropStart,
        yCropEnd,
      })}/${resize({ scale, cropWidth, cropHeight })}`;
    } else {
      cropped = resize({ scale, cropWidth, cropHeight });
    }

    return `${src}/m/${cropped}/filters:quality(80)`;
  };

  const isSvg = filename?.endsWith(".svg");

  return !!filename ? (
    <div
      className={classes}
      style={
        !isSvg
          ? {
              aspectRatio: isSmallScreen
                ? aspectMobile || intrinsicAspect
                : aspectDesktop || aspectMobile || intrinsicAspect,
            }
          : undefined
      }
    >
      {isSvg ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className="article-image-svg"
          alt={alt}
          src={filename}
          width="100%"
          onLoad={() => setImageIsLoaded(true)}
        />
      ) : typeof isSmallScreen !== "undefined" ? (
        <NextImage
          loader={loader}
          src={filename}
          alt={alt?.trim() ?? ""}
          objectFit="cover"
          onLoadingComplete={() => setImageIsLoaded(true)}
          layout="fill"
          sizes={sizes || "100vw"}
          priority={priority}
        />
      ) : null}
    </div>
  ) : (
    <h6 className={`fallback ${classes}`}>Please add an image.</h6>
  );
};

export default Image;
