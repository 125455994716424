import React, { useState } from "react";
import dynamic from "next/dynamic";
import Image from "@/components/Image/image";
import classNames from "classnames";
import ReactPlayer from "react-player/lazy";
import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay";
import styles from "./video-card.module.scss";
import { VideoCardStoryblok } from "@/types/component-types-sb";

const Modal = dynamic(() =>
  import("@ef-global/web-ui-react/lib/components/Modal").then(
    (mod) => mod.Modal
  )
);

const VideoCard = (props: VideoCardStoryblok) => {
  const { story, aspectMobile, aspectDesktop, className, children, childKey } =
    props;

  const [openModal, setOpenModal] = useState(false);
  const handleClickOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const { poster, title, url } = story;

  const classes = classNames(styles.videoCard, className);

  return (
    <>
      <div onClick={handleClickOpen} className={classes} key={childKey}>
        {poster?.filename && (
          <div className={styles.videoCardWrap}>
            <Image
              alt={poster?.alt}
              aspectMobile={aspectMobile}
              aspectDesktop={aspectDesktop}
              filename={poster?.filename}
            />
            <div className={styles.videoCardOverlay}>
              <PlayButton />
            </div>
          </div>
        )}
        <div className="index-item__title">
          {children ? (
            children
          ) : (
            <p className="u-mb-0 ef-text-ui">
              <strong>{title}</strong>
            </p>
          )}
        </div>
      </div>
      <Modal
        className="ef-modal -l"
        isOpen={openModal}
        onDismiss={handleClose}
        size={"l"}
        overlayClassName={{
          base: "ef-modal__overlay -lightbox -inv",
          afterOpen: "ef-modal__overlay--after-open",
          beforeClose: "ef-modal__overlay--before-close",
        }}
        htmlOpenClassName="ef-modal--active"
        ariaHideApp={false}
        closeTimeoutMS={150}
        open={openModal}
      >
        <div className="modal__wrap">
          <div className="ef-container">
            <div className="modal__content">
              <div className="modal__player-video-wrap">
                <div
                  className="modal__player"
                  style={{ width: "100%", height: "100%" }}
                >
                  <ReactPlayer
                    url={url}
                    className="modal__player-video"
                    width="100%"
                    height="100%"
                    playing={true}
                    controls={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const PlayButton = () => (
  <div className={styles.videoCardPlay}>
    <IconPlay />
  </div>
);

export default VideoCard;
