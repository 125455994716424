import {
  CheckboxInput,
  RadioInput,
  SelectInput,
  TextareaInput,
  TextInput,
} from "@/components/Form/inputs";
import styles from "./consultation-form.module.scss";
import { useForm } from "react-hook-form";
import Button from "@/components/Button/button";
import CountryList from "@/components/Form/country-list";
import classnames from "classnames";
import React, { createElement } from "react";
import Components from "@/components/components";
import * as gtag from "@/lib/gtag";
import { ConsultationFormStoryblok } from "@/types/component-types-sb";

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface Props {
  blok: ConsultationFormStoryblok;
}

const ConsultationForm = (props: Props) => {
  const {
    blok: {
      submit_url = "",
      submit_success_heading,
      submit_success_message,
      submit_success_embed,
    },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data: unknown) => {
    await fetch(submit_url, {
      method: "POST",
      headers: {
        accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    gtag.event({
      action: "User submitted consultation form success",
      category: "Consultation",
      label: `Consultation-Form`,
      value: true,
    });
  };

  return !isSubmitSuccessful ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.consultationWrapper}
    >
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            Which level of coaching are you interested in?
          </legend>
          <div className={styles.consultationCheckboxGroup}>
            <RadioInput
              tabIndex={1}
              inputClassName={styles.scheduleCheckboxInput}
              {...register("plan")}
              value="essentials"
            >
              Essentials from US$ 206/month
            </RadioInput>
            <RadioInput
              inputClassName={styles.scheduleCheckboxInput}
              {...register("plan")}
              value="core"
            >
              Core from US$ 355/month
            </RadioInput>
            <RadioInput
              inputClassName={styles.scheduleCheckboxInput}
              {...register("plan")}
              value="premium"
            >
              Premium from US$ 563/month
            </RadioInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset,
          styles.personalDetails
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>Personal details</legend>
          <TextInput
            valid={!errors.name && touchedFields.name}
            invalid={errors.name && touchedFields.name}
            placeholder={"Name"}
            tabIndex={1}
            {...register("name", { required: "Please enter your name" })}
          />
          <SelectInput
            valid={!errors.country && touchedFields.country}
            invalid={errors.country && touchedFields.country}
            tabIndex={3}
            {...register("country", { required: "Please select your country" })}
            className={styles.consultationSelect}
          >
            <CountryList />
          </SelectInput>
        </div>
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label u-s-mt-l u-m-mt-0 css-0`}>
            How should we contact you?
          </legend>
          <TextInput
            valid={!errors.email && touchedFields.email}
            invalid={errors.email && touchedFields.email}
            placeholder={"Email"}
            tabIndex={2}
            {...register("email", {
              required: "Please enter your email",
              pattern: EMAIL_REGEX,
            })}
          />
          <TextInput
            valid={!errors.mobile && touchedFields.mobile}
            placeholder={"Mobile phone"}
            tabIndex={4}
            {...register("mobile", {
              required: "Please enter your mobile number",
            })}
          />
        </div>
      </fieldset>
      <hr className={styles.divider} />
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            How many years have you been riding a bike or training consistently?
          </legend>
          <div className={styles.consultationRadioGroup}>
            <RadioInput
              valid={!errors.howManyYears}
              invalid={errors.howManyYears}
              tabIndex={5}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyYears", { required: true })}
              value={"0-1 Years"}
            >
              0-1 Years
            </RadioInput>
            <RadioInput
              valid={!errors.howManyYears}
              invalid={errors.howManyYears}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyYears", { required: true })}
              value={"1-3 Years"}
            >
              1-3 Years
            </RadioInput>
            <RadioInput
              valid={!errors.howManyYears}
              invalid={errors.howManyYears}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyYears", { required: true })}
              value={"3-5 Years"}
            >
              3-5 Years
            </RadioInput>
            <RadioInput
              valid={!errors.howManyYears}
              invalid={errors.howManyYears}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyYears", { required: true })}
              value={"6-10 Years"}
            >
              6-10 Years
            </RadioInput>
            <RadioInput
              valid={!errors.howManyYears}
              invalid={errors.howManyYears}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyYears", { required: true })}
              value={"10+ Years"}
            >
              10+ Years
            </RadioInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            How many days per week do you currently ride?
          </legend>
          <div className={styles.consultationRadioGroup}>
            <RadioInput
              valid={!errors.howManyDaysPerWeek}
              invalid={errors.howManyDaysPerWeek}
              tabIndex={6}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyDaysPerWeek", { required: true })}
              value={"1-2 Days"}
            >
              1-2 Days
            </RadioInput>
            <RadioInput
              valid={!errors.howManyDaysPerWeek}
              invalid={errors.howManyDaysPerWeek}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyDaysPerWeek", { required: true })}
              value={"3-4 Days"}
            >
              3-4 Days
            </RadioInput>
            <RadioInput
              valid={!errors.howManyDaysPerWeek}
              invalid={errors.howManyDaysPerWeek}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyDaysPerWeek", { required: true })}
              value={"5-6 Days"}
            >
              5-6 Days
            </RadioInput>
            <RadioInput
              valid={!errors.howManyDaysPerWeek}
              invalid={errors.howManyDaysPerWeek}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyDaysPerWeek", { required: true })}
              value={"Every day"}
            >
              Every day
            </RadioInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            How many hours of riding per week have you averaged over the last 12
            months?
          </legend>
          <div className={styles.consultationRadioGroup}>
            <RadioInput
              valid={!errors.howManyHoursOfRidingPerWeek}
              invalid={errors.howManyHoursOfRidingPerWeek}
              tabIndex={7}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyHoursOfRidingPerWeek", { required: true })}
              value={"1-3 Hours"}
            >
              1-3 Hours
            </RadioInput>
            <RadioInput
              valid={!errors.howManyHoursOfRidingPerWeek}
              invalid={errors.howManyHoursOfRidingPerWeek}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyHoursOfRidingPerWeek", { required: true })}
              value={"4-7 Hours"}
            >
              4-7 Hours
            </RadioInput>
            <RadioInput
              valid={!errors.howManyHoursOfRidingPerWeek}
              invalid={errors.howManyHoursOfRidingPerWeek}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyHoursOfRidingPerWeek", { required: true })}
              value={"8-12 Hours"}
            >
              8-12 Hours
            </RadioInput>
            <RadioInput
              valid={!errors.howManyHoursOfRidingPerWeek}
              invalid={errors.howManyHoursOfRidingPerWeek}
              inputClassName={styles.scheduleRadioInput}
              {...register("howManyHoursOfRidingPerWeek", { required: true })}
              value={"13-20 Hours"}
            >
              13-20 Hours
            </RadioInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            Do you consider yourself competitive or have an interest in racing?
          </legend>
          <div className={styles.consultationRadioGroup}>
            <RadioInput
              valid={!errors.areYouCompetitive}
              invalid={errors.areYouCompetitive}
              tabIndex={10}
              inputClassName={styles.scheduleRadioInput}
              {...register("areYouCompetitive", { required: true })}
              value={"Yes"}
            >
              Yes
            </RadioInput>
            <RadioInput
              valid={!errors.areYouCompetitive}
              invalid={errors.areYouCompetitive}
              inputClassName={styles.scheduleRadioInput}
              {...register("areYouCompetitive", { required: true })}
              value={"No"}
            >
              No
            </RadioInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            What are your short-term cycling goals?
          </legend>
          <TextareaInput
            valid={!errors.shortTermGoals && touchedFields.shortTermGoals}
            invalid={errors.shortTermGoals && touchedFields.shortTermGoals}
            tabIndex={11}
            {...register("shortTermGoals", { required: true })}
            placeholder={"What are your short-term cycling goals?"}
          />
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            What are your long-term cycling goals?
          </legend>
          <TextareaInput
            valid={!errors.longTermGoals && touchedFields.longTermGoals}
            invalid={errors.longTermGoals && touchedFields.longTermGoals}
            tabIndex={12}
            {...register("longTermGoals", { required: true })}
            placeholder={"What are your long-term cycling goals?"}
          />
        </div>
      </fieldset>
      <hr className={styles.divider} />
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            What interests you most about working with Team EF Coaching?
          </legend>
          <TextareaInput
            valid={!errors.interestsMost && touchedFields.interestsMost}
            invalid={errors.interestsMost && touchedFields.interestsMost}
            tabIndex={13}
            {...register("interestsMost", { required: true })}
            placeholder={
              "What interests you most about working with Team EF Coaching?"
            }
          />
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            Have you been referred by a friend? If so what is their name?
          </legend>
          <TextInput
            valid={!errors.referredByFriend && touchedFields.referredByFriend}
            invalid={errors.referredByFriend && touchedFields.referredByFriend}
            placeholder={"Name"}
            tabIndex={14}
            {...register("referredByFriend")}
          />
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            How did you hear about Team EF Coaching?
          </legend>
          <TextareaInput
            valid={!errors.hearAboutUs && touchedFields.hearAboutUs}
            invalid={errors.hearAboutUs && touchedFields.hearAboutUs}
            tabIndex={15}
            {...register("hearAboutUs", { required: true })}
            placeholder={"How did you hear about Team EF Coaching?"}
          />
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            Tell us a little about yourself (sporting history, goals, lifestyle,
            etc.)
          </legend>
          <TextareaInput
            valid={!errors.aboutYourself && touchedFields.aboutYourself}
            invalid={errors.aboutYourself && touchedFields.aboutYourself}
            tabIndex={16}
            {...register("aboutYourself", { required: true })}
            placeholder={
              "Tell us a little about yourself (sporting history, goals, lifestyle, etc.)"
            }
          />
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            Do you take part in additional off bike exercise activities? i.e.
            Yoga, running, gym, hiking, skiing. etc (please list)
          </legend>
          <TextareaInput
            valid={!errors.offBikeActivities && touchedFields.offBikeActivities}
            invalid={
              errors.offBikeActivities && touchedFields.offBikeActivities
            }
            tabIndex={17}
            {...register("offBikeActivities", { required: true })}
            placeholder={
              "Do you take part in additional off bike exercise activities? i.e. Yoga, running, gym, hiking, skiing. etc (please list)\n"
            }
          />
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <legend className={`ef-form-label css-0`}>
            Do you own any of the following?
          </legend>
          <div className={styles.consultationCheckboxGroup}>
            <CheckboxInput
              tabIndex={18}
              inputClassName={styles.scheduleCheckboxInput}
              {...register("own")}
              value="power-meter"
            >
              Power meter
            </CheckboxInput>
            <CheckboxInput
              inputClassName={styles.scheduleCheckboxInput}
              {...register("own")}
              value="heart-rate-monitor"
            >
              Heart Rate Monitor
            </CheckboxInput>
            <CheckboxInput
              inputClassName={styles.scheduleCheckboxInput}
              {...register("own")}
              value="bike-computer"
            >
              Bike Computer
            </CheckboxInput>
            <CheckboxInput
              inputClassName={styles.scheduleCheckboxInput}
              {...register("own")}
              value="indoor-trainer"
            >
              Indoor trainer
            </CheckboxInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <div className={styles.consultationCheckboxGroup}>
            <CheckboxInput
              valid={!errors.newsletter}
              invalid={errors.newsletter}
              inputClassName={styles.scheduleCheckboxInput}
              tabIndex={19}
              {...register("newsletter")}
            >
              We would love to keep you informed of new products, training
              advice and other Team EF Coaching news, please tick the box if you
              do not wish to receive the Team EF Coaching newsletter.
            </CheckboxInput>
          </div>
        </div>
      </fieldset>
      <fieldset
        className={classnames(
          styles.consultationSection,
          styles.efFormFieldset
        )}
      >
        <div className={styles.consultationDetails}>
          <div className={styles.consultationCheckboxGroup}>
            <CheckboxInput
              valid={!errors.policy}
              invalid={errors.policy}
              inputClassName={styles.scheduleCheckboxInput}
              tabIndex={20}
              {...register("policy", { required: true })}
            >
              I agree to my personal data being processed in accordance with our{" "}
              <a href="/privacy-policy" title="privacy policy" target="_blank">
                Privacy Policy.
              </a>
            </CheckboxInput>
          </div>
        </div>
      </fieldset>
      <div className={styles.consultationSection}>
        <div className={styles.consultationDetails}>
          <Button
            type={`submit`}
            filled
            secondary
            tabIndex={21}
            className={styles.submitButton}
          >
            Send
          </Button>
        </div>
      </div>
    </form>
  ) : (
    <div className={styles.successContainer}>
      <p className={`ef-h5 u-mt-xxl u-mb-0`}>{submit_success_heading}</p>
      <p className={`ef-h6 u-mt-m u-mb-0 `}>{submit_success_message}</p>
      <div>
        {submit_success_embed &&
          submit_success_embed.map((blok) => {
            return createElement(Components(blok.component), {
              blok,
              key: blok._uid,
            });
          })}
      </div>
    </div>
  );
};

export default ConsultationForm;
