export const originalDimensions = (filename: string) => ({
  width: filename ? parseInt(filename.split("/")[5].split("x")[0]) : 0,
  height: filename ? parseInt(filename.split("/")[5].split("x")[1]) : 0,
});

export const applyAspectRatioAndReturnCropSize = ({
  width,
  height,
  aspectRatio = 1,
}: {
  width: number | string;
  height: number | string;
  aspectRatio?: number;
}) => {
  let computedWidth, computedHeight;

  computedWidth = Number(width);
  computedHeight = Math.round(computedWidth / aspectRatio);

  if (computedHeight > Number(height)) {
    computedHeight = Number(height);
    computedWidth = Math.round(Number(height) * aspectRatio);
  }

  return { cropWidth: computedWidth, cropHeight: computedHeight };
};

export const cropDimsAroundFocusPoint = ({
  originalWidth,
  originalHeight,
  cropWidth,
  cropHeight,
  focus,
}: {
  originalWidth: number | string;
  originalHeight: number | string;
  cropWidth: number | string;
  cropHeight: number | string;
  focus: string;
}) => {
  const [topLeftXY] = focus.split(":");
  const [focusX, focusY] = topLeftXY.split("x");
  const halfWidth = Number(cropWidth) / 2;
  const halfHeight = Number(cropHeight) / 2;
  let xCropStart, yCropStart, xCropEnd, yCropEnd;

  if (Number(focusX) - halfWidth > 0) {
    if (Number(focusX) + halfWidth > Number(originalWidth)) {
      xCropStart =
        Number(originalWidth) - Number(cropWidth) > 0
          ? Number(originalWidth) - Number(cropWidth)
          : 0;
      xCropEnd = originalWidth;
    } else {
      xCropStart = Math.round(Number(focusX) - halfWidth);
      xCropEnd = Math.round(Number(focusX) + halfWidth);
    }
  } else {
    xCropStart = 0;
    xCropEnd = cropWidth;
  }

  if (Number(focusY) - Number(halfHeight) > 0) {
    if (Number(focusY) + Number(halfHeight) > Number(originalHeight)) {
      yCropStart =
        Number(originalHeight) - Number(cropHeight) > 0
          ? Number(originalHeight) - Number(cropHeight)
          : 0;
      yCropEnd = originalHeight;
    } else {
      yCropStart = Math.round(Number(focusY) - halfHeight);
      yCropEnd = Math.round(Number(focusX) + halfHeight);
    }
  } else {
    yCropStart = 0;
    yCropEnd = cropHeight;
  }

  return { xCropStart, yCropStart, xCropEnd, yCropEnd };
};

const scaled = (d: number | string, scale: number | string) =>
  Math.round(Number(d) * Number(scale));

export const cropWithFocusPoint = ({
  xCropStart,
  xCropEnd,
  yCropStart,
  yCropEnd,
}: {
  xCropStart: number | string;
  xCropEnd: number | string;
  yCropStart: number | string;
  yCropEnd: number | string;
}) => {
  return `${xCropStart}x${yCropStart}:${xCropEnd}x${yCropEnd}`;
};

export const resize = ({
  scale,
  cropWidth,
  cropHeight,
}: {
  scale: number | string;
  cropWidth: number | string;
  cropHeight: number | string;
}) => {
  return `${scaled(cropWidth, scale)}x${scaled(cropHeight, scale)}`;
};

export const cropDims = ({
  width,
  height,
  aspectRatio = `${width}/${height}`,
}: {
  width: number | string;
  height: number | string;
  aspectRatio?: string;
}) => {
  const [aspectX, aspectY] = aspectRatio.split("/");
  const decimalAspectRatio = Number(aspectX) / Number(aspectY);
  const { cropWidth, cropHeight } = applyAspectRatioAndReturnCropSize({
    width,
    height,
    aspectRatio: decimalAspectRatio,
  });
  return { cropWidth, cropHeight };
};
