import s from "./girona-camp.module.scss";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "@/components/Form/inputs";
import Button from "@/components/Button/button";
import classnames from "classnames";
import { GironaCampStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: GironaCampStoryblok;
}

const GironaCamp = (props: Props) => {
  const {
    blok: {
      submit_url,
      submit_success_message,
      heading_text,
      heading_description,
    },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    try {
      await fetch(submit_url || "", {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className={s.gironaForm}>
      {!isSubmitSuccessful ? (
        <>
          <div className={s.headingWrapper}>
            <h3 className={s.headingText}>{heading_text}</h3>
            <p className={s.headingDescription}>{heading_description}</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.gironaFormInputs}>
              <TextInput
                valid={!errors.name && touchedFields.name}
                invalid={errors.name && touchedFields.name}
                placeholder={"Name"}
                {...register("name", { required: "Please enter your name" })}
              />
              <TextInput
                valid={!errors.email && touchedFields.email}
                invalid={errors.email && touchedFields.email}
                placeholder={"Email"}
                {...register("email", {
                  required: "Please enter your email",
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
              />
            </div>
            <Button
              accent_color="accentPink"
              size="medium"
              icon={"IconArrowRight"}
              className={s.formButton}
              type="submit"
            >
              Submit interest
            </Button>
          </form>
        </>
      ) : (
        <div className={s.headingWrapper}>
          <p className={classnames(s.headingDescription, `ef-h6`)}>
            {submit_success_message}
          </p>
        </div>
      )}
    </div>
  );
};

export default GironaCamp;
