import React from "react";
import { Table } from "@ef-global/web-ui-react/lib/components/Table";
import classNames from "classnames";
import SbEditable from "storyblok-react";
import styles from "./web-ui-table.module.scss";
import type { WebUiTableStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: WebUiTableStoryblok;
}

const WebUITable = (props: Props) => {
  const {
    blok: {
      bordered_table,
      striped_table,
      row_highlight,
      col_highlight,
      font_size,
      content,
    },
  } = props;
  const classes = classNames(
    styles["ef-table-w"],
    styles["ef-table"],
    bordered_table ? styles.bordered : "",
    striped_table ? styles.striped : "",
    row_highlight ? styles["unset-header-styles"] : "",
    col_highlight ? styles["unset-col-header-styles"] : "",
    font_size
  );
  const isTableHeadEmpty = () => {
    return typeof content?.thead?.find((th) => th.value !== "") !== "undefined";
  };

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <Table className={classes}>
        {isTableHeadEmpty() && (
          <Table.Head>
            <Table.Row>
              {content &&
                content.thead &&
                content.thead.map((th) => (
                  <Table.Cell key={th._uid}>{th.value}</Table.Cell>
                ))}
            </Table.Row>
          </Table.Head>
        )}
        <Table.Body>
          {content &&
            content.tbody &&
            content.tbody.map((tr) => {
              return (
                <Table.Row key={tr._uid}>
                  {tr.body &&
                    tr.body.map((td) => (
                      <Table.Cell key={td._uid}>{td.value}</Table.Cell>
                    ))}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </SbEditable>
  );
};

export default WebUITable;
