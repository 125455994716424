import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";

import styles from "./inline-video.module.scss";
import { InlineVideoStoryblok } from "@/types/component-types-sb";

const SbEditable = dynamic(() => import("storyblok-react"));
const ReactPlayer = dynamic(() => import("react-player/lazy"));

interface Props {
  blok: InlineVideoStoryblok;
}

const InlineVideo = (props: Props) => {
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  const {
    blok: { url },
  } = props;

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <div className={styles.playerInner} ref={ref}>
        <ReactPlayer
          url={url || ""}
          className={styles.reactPlayer}
          width="100%"
          height="100%"
          playing={true}
          playsinline
          loop={true}
          muted
        />
      </div>
    </SbEditable>
  );
};

export default InlineVideo;
