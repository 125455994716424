import StoryCards from "@/components/StoryCards/story-cards";
import VideoCard from "@/components/Video/Card/video-card";
import styles from "./video-cards.module.scss";
import classnames from "classnames";
import { VideoCardsStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: VideoCardsStoryblok;
}

const VideoCards = (props: Props) => {
  const {
    blok: { items },
  } = props;

  return (
    <StoryCards
      stories={items || []}
      layout={["carousel", "2"]}
      aspect={["3/2", "3/2"]}
      storiesPerRow={["2", "2"]}
      className={classnames(styles.videoCards)}
      sort={"desc"}
      CardComponent={VideoCard}
    />
  );
};

export default VideoCards;
